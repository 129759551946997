'use strict'

angular
  .module 'mundoAdmin'
  .config ($stateProvider) ->
    $stateProvider
      .state 'admin',
        url: '/admin'
        templateUrl: 'mundo-admin/views/mundo-admin.tpl.html'
        controller: 'MundoAdminCtrl'
        controllerAs: 'adminCtrl'
        data:
          navigation: true
